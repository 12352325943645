import React, { useRef } from 'react';
import axios from 'axios';

export default function ChartFilters({ filterConfig, filterOptions, baseUri, chartId, options, setOptions }) {
  const filterRefs = useRef({}); // Store refs for each filter input

  const handleFilterClick = async () => {
    // Construct the query string
    const filters = filterConfig.filters
      .map((filter) => {
        const value = filterRefs.current[filter.key]?.value || "-- All --"; // Get the value or default
        return `${filter.key}=${encodeURIComponent(value)}`; // Encode for safety
      })
      .join("&");

    const endpoint = `${baseUri}/chart/bar/${chartId}?${filters}`;
    console.log('Constructed Endpoint:', endpoint);

    try {
        const response = await axios.get(endpoint);
        console.log('CHART DATA', response.data);
        console.log('OPTIONS', options);
        const updatedOptions = {
            ...options,
            series: response.data.series,
          };
      
          setOptions(updatedOptions); // Update the state with the new options
    } catch (error) {
        console.error('Error fetching chart data:', error);
    }
  };

  return (
    <div style={{ marginBottom: '1rem', fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif', fontSize: '14px' }}>
      <div style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
        {filterConfig.filters.map((filter) => (
          <div key={filter.key}>
            <label htmlFor={filter.key} style={{ display: 'block', fontWeight: 'bold', marginBottom: '0.5rem' }}>
              {filter.title}
            </label>
            <select
              id={filter.key}
              name={filter.key}
              ref={(el) => (filterRefs.current[filter.key] = el)} // Save the reference
              style={{
                padding: '0.5rem',
                fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
                fontSize: '14px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              }}
            >
              {filterOptions[filter.key]?.map((option, index) => (
                <option key={index} value={option === "-- All --" ? "" : option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        ))}
      </div>
      <button
        onClick={handleFilterClick}
        style={{
          padding: '0.5rem 1rem',
          fontFamily: '"Lucida Grande", "Lucida Sans Unicode", Arial, Helvetica, sans-serif',
          fontSize: '14px',
          color: '#fff',
          backgroundColor: '#007BFF',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
      >
        Filter
      </button>
    </div>
  );
  
}

