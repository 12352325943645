import './Portal.css';
import PortalTopic from './PortalTopic';
import PortalIndicators from './PortalIndicators';
import React, {useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Loader from "../util/Loader";
import IconButton from '@mui/material/IconButton';
import ChatIcon from '@mui/icons-material/AutoAwesome';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import showdown  from 'showdown';

import { v4 as uuidv4 } from 'uuid';
const chatId = uuidv4(); 


let converter = new showdown.Converter();

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}


export default function PortalView(){

    const [categories, setCategories] = useState([]);
    const [categoryActive, setCategoryActive] = useState(-1);
    const [categoryActiveName, setCategoryActiveName] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [topics, setTopics] = useState([]);
    const [indicatorSubCategories, setIndicatorSubCategories] = useState([]);
    const [indicators, setIndicators] = useState([]);
    const [indicatorActive, setIndicatorActive] = useState(-1);
    const [chartTitle, setChartTitle] = useState('');
    const [chartTitle2, setChartTitle2] = useState('');
    const [chartLink, setChartLink] = useState('#');
    const [isIndicator, showIsIndicator] = useState(false);
    const baseApiUri = process.env.REACT_APP_API_URL;
    const[open, setOpen] = useState(false);
    const baseAppUri = process.env.REACT_APP_APP_URI;
    const { id } = useParams();
    const chatBoxRef = useRef(null); // Ref for the chat box container
    const[requestSortKey, setRequestSortKey] = useState(0);
    //const [loadingDots, setLoadingDots] = useState(0); // Tracks dot animation
    //const [isAssistantTyping, setIsAssistantTyping] = useState(false);

    //Chat
    // State to control the drawer visibility
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // State to manage the chat messages (both user and AI responses)
    const [messages, setMessages] = useState([]);
    // State to manage the user input in the text field
    const [userInput, setUserInput] = useState('');

    const handleSendMessage = async () => {
        if (userInput.trim() === '') return;
    
        // Step 1: Add user's message to the chat
        const newMessage = { sender: 'user', text: userInput };
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setUserInput(''); // Clear input field
    
        try {
            // Step 2: Send the message to the AI assistant
            const payload = {
                key: '09848734-8745-afrt-8745-8745873487', // Replace with your API key
                id: chatId, // Generate a unique id for the request (if needed)
                type: 'ccfap', // Replace with the actual type, if needed
                sortKey: parseInt(requestSortKey), // Initial sortKey is 0 for the first message
                query: userInput,
                stream: true
            };

            const response = await axios.post(`${baseApiUri}/ai/completion`, payload, { 'Content-Type': 'application/json' });
    
            /*
            const response = await fetch(`${baseApiUri}/ai/completion`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload)
            });
            */
    
           let data = await response.data;
    
           /*
            if (!response.ok) {
                throw new Error(`Error: ${data.message || 'Request failed'}`);
            }
                */
    
            console.log('API Response:', data); // Logs the initial response
    
            const { id, sortKey } = data; // Extract id and sortKey from the response
            console.log('ID:', id); 
            console.log('SORT KEY:', sortKey);

    
            // Step 3: Poll the AI assistant for the response
            pollForAssistantResponse(id, sortKey);
    
        } catch (error) {
            console.error('Error sending message to AI assistant:', error);
            const errorMessage = { sender: 'assistant', text: 'Something went wrong. Please try again.' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        }
    };

    const pollForAssistantResponse = async (id, sortKey) => {
        console.log('Polling for assistant response...');
        let attempt = 0;
    
        const poll = async () => {
            let endpoint = `${baseApiUri}/ai/completion/${id}/${sortKey}`;
            console.log('POLLING ENDPOINT', endpoint);
            try {
                let response = await axios.get(endpoint);
                let data = response.data;
    
                console.log(`Polling Attempt #${attempt}:`, data); // Logs the response from the API
    
                // Step 1: Check if the "Analyzing the data ..." message is already present
                setMessages((prevMessages) => {
                    const hasAnalyzingMessage = prevMessages.some(msg => msg.text === 'Analyzing the data...');
                    if (!hasAnalyzingMessage) {
                        return [...prevMessages, { sender: 'assistant', text: 'Analyzing the data ...' }];
                    }
                    return prevMessages;
                });
    
                let hasMore = true;
    
                while(hasMore){
                    try {
                        let endpoint =  `${baseApiUri}/ai/completion/${id}/${sortKey}`;
                        let completion = await axios.get(endpoint);
                        let status = completion.data.response.status;
                        let newMessageFragment = completion.data.response.message;
    
                        console.log('New message fragment:', newMessageFragment);
    
                        if (newMessageFragment && newMessageFragment !== '') {
                            // Step 1: convert the markdown to HTML
                            let messageHtml = converter.makeHtml(newMessageFragment);
                            // Step 2: Update the assistant's message incrementally
                            setMessages((prevMessages) => {
                                const updatedMessages = prevMessages.map((msg, index) => {
                                    if (index === prevMessages.length - 1 && msg.sender === 'assistant') {
                                        // Update the last message from assistant
                                        return { ...msg, text: messageHtml.replaceAll('<a','<a target="_blank" ') };
                                    }
                                    return msg;
                                });
                                return updatedMessages;
                            });
                        }
    
                        if (status === 'success') {
                            let newSortKey = requestSortKey;
                            setRequestSortKey(newSortKey+1);
                            hasMore = false;
                            let messageHtml = converter.makeHtml(newMessageFragment);
                            // Step 3: Finalize the assistant message once status is "success"
                            setMessages((prevMessages) => {
                                const updatedMessages = prevMessages.map((msg, index) => {
                                    if (index === prevMessages.length - 1 && msg.sender === 'assistant') {
                                        // Update the last message from assistant to be the final message
                                        return { ...msg, text: messageHtml.replaceAll('<a','<a target="_blank" ') };
                                    }
                                    return msg;
                                });
                                return updatedMessages;
                            });
                        }
    
                    } catch(e) {
                        console.error('Error polling for completion response:', e);
                        hasMore = false;
                    }
    
                    await sleep(100);
                }
    
            } catch (error) {
                console.error('Error polling for assistant response:', error);
                const errorMessage = { sender: 'assistant', text: 'The assistant did not respond. Please try again later.' };
                setMessages((prevMessages) => [...prevMessages, errorMessage]);
            }
        };
    
        poll();
    };
    
    
    
    



    //functions to manage the loading indicator
    const showModal = async () => {
        return setOpen(true);
    }
    
    const hideModal = async () => {
        return setOpen(false);
    }


   const ref = useRef(null);

   
    const showIndicator = async (indicator) => {

        console.log(`showing indicator for`, indicator);

        setIndicatorActive(indicator.id);
        showIsIndicator(true);
        //console.log(baseAppUri);
        ref.current?.scrollIntoView({ behavior: 'smooth' });
        console.log('BASE APP URI', baseAppUri);
        console.log('INDICATOR', indicator);
        //handle multiple charts for an indicator
        if(indicator.chart_url.indexOf('~') > -1){
            console.log('MUTIPLE CHARTS');
            let urls = indicator.chart_url.split('~');
            let titles = indicator.title.split('~');
            let src = baseAppUri+urls[0];
            let src2 = baseAppUri+urls[1];
            let title = titles[1];
            let title2 = titles[2];
            let link = indicator.link;
            setChartTitle(title);
            setChartTitle2(title2);
            setChartLink(link);
            document.getElementById("indicatorFrame").src = src; 
            document.getElementById("indicatorFrame2").src = src2; 
        } else {
            console.log('SINGLE CHART');
            let src = baseAppUri+indicator.chart_url;
            let title = indicator.title;
            let link = indicator.link;
            setChartTitle(title);
            setChartLink(link);
            document.getElementById("indicatorFrame").src = src; 
        }

        
        console.log('ACTIVE INDICATOR', indicatorActive);
   
             
    }

    const showSubcategory = async (category, id, topic) => {
        console.log('Portal topic', topic);
        console.log(`Portal showing subcategory for ${category}`);
        console.log('Portal sub categories', subCategories);
        console.log('Portal category clicked element', id);
        setCategoryActive(id);
        setCategoryActiveName(`/ ${category}`)
        //document.getElementById(elt);
        let filteredSubCategories = subCategories.filter(function (el) {
            //console.log(el);
            return el.topic === topic && el.category === id;
        }
        );
        console.log('FILTERED SUB CATEGORIES', filteredSubCategories);
        setIndicatorSubCategories(filteredSubCategories);
        
    }

    useEffect(() => {
        if (chatBoxRef.current) {
            chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
        }
    }, [messages]); // Only triggers when `messages` changes

    useEffect( () => {

        console.log('Chat ID', chatId)

        const getDashboard = async () => {

            const getCategories = async (id) => {

                let endpoint = `${baseApiUri}/portals/categories/${id}`;
                //let endpoint = `${baseApiUri}/table/table/dashboard:categories:table`;
                let resp = await axios.get(endpoint);
                console.log('CAT', resp.data);
                return resp.data;
                      
            }
        

            const getTopics = async (id) => {
                showModal();
                let topicsEndpoint = `${baseApiUri}/portals/topics/${id}`;
                //let topicsEndpoint = `${baseApiUri}/table/table/dashboard:topics:table`;
                //let endpoint = id;
                let resp = await axios.get(topicsEndpoint);
                //console.log('TOPICS',resp.data);
                //setTopics(resp.data.rows);
                setTopics(resp.data);
                //return resp.data.rows.reverse();
                return resp.data;
            }

            const getIndicators = async (s) => {

                //console.log(isIndicator);
                let endpoint = `${baseApiUri}/portals/indicators/${id}`;
                //let endpoint = `${baseApiUri}/table/table/dashboard:indicators:table`;
                let resp = await axios.get(endpoint);
                //for each of the indicators, add them to the proper subcategories
                /*
                let subCategoryIndicators = {};
                //console.log('SUB CATEGORIES', s);
                for(let i = 0; i < subCategories.length; i++){
                    let subcategory = subCategories[i];
                    //console.log('SUB CATEGORIES (Integrations)', subcategory);
                }
                */
                //console.log('SUB CAT INDICATORS',subCategoryIndicators);
                hideModal();
                return resp.data;
                
            }

            const getSubCategories = async (id) => {

                let endpoint = `${baseApiUri}/portals/subcategories/${id}`;
                //let endpoint = `${baseApiUri}/table/table/dashboard:subcategories:table`;
                let resp = await axios.get(endpoint);
                //let s = resp.data;
                //put the subcategories into an object we can filter on
                return resp.data;
                
                //console.log('TOPICS',topics);
            }

            //get topics
            let t = await getTopics(id);
            console.log('TOPICS', t);
            setTopics(t);
            //get categories
            let c = await getCategories(id);
            /*
            c.sort(function(a, b) {
                var textA = a.Name.toUpperCase();
                var textB = b.Name.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
            */
            //console.log('CATEGORIES', c);
            setCategories(c);
            //get subcategories  
            let s = await getSubCategories(id);
           // console.log('SUB CATEGORIES', s);
            setSubCategories(s);
             //get subcategories  
             let i = await getIndicators(s);
             console.log('INDICATORS', i);
             setIndicators(i);
             
            //console.log('TOPICS',topics);          
           // console.log('CATEGORIES', topicCategories);
        }

        getDashboard();
    }, [isIndicator, baseApiUri,id])

    return (

        <Box sx={{padding: '20px'}}>
            <IconButton 
                onClick={() => setIsDrawerOpen(true)} 
                sx={{ 
                    position: 'fixed', 
                    bottom: 20, 
                    right: 20, 
                    backgroundColor: '#1976d2', 
                    color: '#fff', 
                    '&:hover': { backgroundColor: '#1565c0' } 
                }}
            >
                <ChatIcon />
            </IconButton>
            <Drawer 
    anchor="right" 
    open={isDrawerOpen} 
    onClose={() => setIsDrawerOpen(false)}
>
    <Box sx={{ width: 600, p: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="h6" sx={{ textAlign: 'center', mb: 2 }}>
            Act 76 AI Assistant
        </Typography>

        {/* Messages */}
        <Box ref={chatBoxRef}  sx={{ flex: 1, overflowY: 'auto', mb: 2 }}>
            {messages.map((message, index) => (
                <Box 
                    key={index} 
                    sx={{ 
                        p: 1, 
                        m: 1, 
                        backgroundColor: message.sender === 'user' ? '#e3f2fd' : '#f1f8e9', 
                        borderRadius: '10px' 
                    }}
                >
                    <strong>{message.sender === 'user' ? 'You' : 'AI'}:</strong> 
            <span 
                dangerouslySetInnerHTML={{ __html: message.text }} 
            />
                </Box>
            ))}
        </Box>

        {/* Input Field */}
        <TextField 
            label="Type a message..." 
            variant="outlined" 
            fullWidth 
            value={userInput} 
            onChange={(e) => setUserInput(e.target.value)} 
            onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            sx={{ mb: 1 }}
        />
        
        {/* Send Button */}
        <Button 
            variant="contained" 
            onClick={handleSendMessage} 
        >
            Send
        </Button>
    </Box>
</Drawer>


            <Loader open={open} />
                <div className='dashboard-container' id="topics" >
                    <Grid container spacing={2}>
                        <Grid item xs={4} className='topic-grid'>
                        {topics.map((t, index) => (
                            <div key={index}>
                            <div className='vecap-container-subheading vecap-bold'>{t.name}</div>
                            <div className="vecap-list">                     
                                <div>
                                    <PortalTopic categoryActive={categoryActive}  topic={t} categories={categories} showSubcategory={showSubcategory} key={index} /></div>                      
                            </div>
                            </div>
                        ))} 
                        </Grid>
                        <Grid item xs={8}>
                            <div className='indicator-heading'>Indicators {categoryActiveName}</div>
                            {indicatorSubCategories.map((i, index) => (
                                                       
                                    <PortalIndicators key={index} name={i.name} indicatorActive={indicatorActive} showIndicator={showIndicator} indicators={indicators} subcategory={i.id_subcategory} category={i.category} topic={i.topic} />                            

                            ))}
                        </Grid>
                    </Grid>

                    </div>

                   
                    <div ref={ref} className='dashboard-container' id="indicators" >

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='indicator-iframe-heading'><a  rel="noreferrer" target="_blank" className='indicator-iframe-heading-link' href={chartLink}>{chartTitle}</a></div>
                            <div>
                            <iframe 
                                className="indicator-frame"
                                id="indicatorFrame"                                
                                height="500" 
                                width="100%" 
                                title="Indicator"
                                >
                                </iframe>
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <div className='indicator-iframe-heading'><a  rel="noreferrer" target="_blank" className='indicator-iframe-heading-link' href={chartLink}>{chartTitle2}</a></div>
                            <div>
                            <iframe 
                                className="indicator-frame"
                                id="indicatorFrame2"                                
                                height="500" 
                                width="100%" 
                                title="Indicator"
                                >
                                </iframe>
                            </div>
                        </Grid>
                    </Grid>

                    </div>
                    
                    
            </Box>    

    );
}